import {Route, Routes} from "react-router-dom";
import DistiplinPage from "../page/distiplin_page";
import Add from "../page/add";

const Router = () => {
    return (
        <Routes>
            <Route path={'/'} element={<DistiplinPage/>}/>
            <Route path={'/add'} element={<Add isEdit={false}/>}/>
            <Route path={'/edit/:id'} element={<Add isEdit={true}/>}/>
        </Routes>
    );
};

export default Router;
