import api from "../../api/api";

class DistiplinesServices {
    async getInfoCard(tokenOrHash) {
        return await api().get(`api/card/fields_load/${tokenOrHash}`,)
    }

    async downloadFile(tokenOrHash) {
        return await api().get(`api/card/download_encrypt/${tokenOrHash}`, {
            responseType: 'blob'
        })
    }

    async uploadFile(formData, tokenOrHash) {
        return await api().post(`api/card/download_decrypt/${tokenOrHash}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
    }

    async getDistiplines() {
        return await api().get(`api/card/${localStorage.getItem('LOCALE')}/disciplines`,)
    }

    async setDistiplinesThemeMusic(tokenOrHash, payload) {
        return await api().post(`api/card/fields_save/${tokenOrHash}`, {
            ...payload
        })
    }

    async clearCard(token) {
        return await api().get(`api/card/create/${token}`)
    }

    async saveCard(hash) {
        return await api().get(`api/element/save/${hash}`)
    }

}

export default new DistiplinesServices()
