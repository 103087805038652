import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/fonts/proximanova_bold.otf'
import './assets/fonts/proximanova_regular.ttf'
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {store} from "./store/store";

export const Context = createContext(null);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Context.Provider value={{store: store}}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Context.Provider>
);

