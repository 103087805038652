import React from 'react';
import s from './styles.module.css'
import {Button} from "antd";
import { useTranslation } from 'react-i18next'
import {useNavigate} from "react-router-dom";

const Action = ({hash, handlerAddedItem, isEdit}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <div className={s.action}>
            <Button className={s.btn2} onClick={() => navigate(`/${hash}`)}>
                {t("cancel")}
            </Button>

            <Button className={s.btn1} onClick={handlerAddedItem} type="primary">
                {isEdit ? t("save") : t("add")}
            </Button>
        </div>
    );
};

export default Action;
