import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {Button, Input} from "antd";
import {observer} from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import {useStore} from "../../../hook/useStore";
import {getCookies} from "../../../utils/getCookies";

const HashHeader = observer(() => {
    const {auth, distiplines} = useStore()

    const [values, setValues] = useState({
        music: null,
        music_theme: null
    })
    const { t } = useTranslation()

    const setDistiplinesThemeMusic = () => {
        const {name: token_name, value: token_value} = getCookies('token')
        const {name: hash_name, value: hash_value} = getCookies('hash')

        distiplines.setDistiplinesThemeMusic(auth.type === 'token' ? token_value : hash_value, {
            ...values,
            discipline_id: distiplines?.card_info?.discipline_id
        })
    }

    useEffect(() => {
        setValues({
            music: distiplines?.card_info?.music,
            music_theme: distiplines?.card_info?.music_theme
        })
    }, [distiplines.loading,distiplines?.card_info])
    return (
        <div className={s.hash_header}>
            <div className={s.hash_header_left}>
                <h2 className={s.title_value}>{distiplines?.card_info?.command_name}</h2>
                <h2 className={s.title_value}>{distiplines?.card_info?.sportsmen}</h2>

                <div className={s.box_write}>
                    <h2 className={s.title_value}>{t("topic")}</h2>
                    <Input onBlur={setDistiplinesThemeMusic} rootClassName={s.input} value={values.music || ''}
                           onChange={(e) => setValues({...values, music: e.target.value})}
                           placeholder={t("inputTopic")}/>

                </div>
            </div>
            <div className={s.hash_header_right}>
                <h2 className={s.title_value}>{distiplines?.card_info?.age_group_name}</h2>
                <h2 className={s.title_value}>{distiplines?.card_info?.discipline_name}</h2>

                <div className={s.box_write}>
                    <h2 className={s.title_value}>{t("topicMusic")}</h2>
                    <Input onBlur={setDistiplinesThemeMusic} rootClassName={s.input} value={values.music_theme || ''}
                           onChange={(e) => setValues({...values, music_theme: e.target.value})}
                           placeholder={t("inputTopicMusic")}/>

                </div>
            </div>

        </div>
    );
});

export default HashHeader;
