function timeToMins(time) {
    let b = time.split(':');
    return b[0] * 60 + +b[1];
}

// Convert minutes to a time in format hh:mm
// Returned value is in range 00  to 24 hrs
function timeFromMins(mins) {
    function z(n) {
        return (n < 10 ? '0' : '') + n;
    }

    let h = (mins / 60 | 0) % 60;
    let m = mins % 60;
    return z(h) + ':' + z(m);
}

// Add two times in hh:mm format
export function addTimes(t0, t1, inc) {
    if (inc === 'p') {
        return timeFromMins(timeToMins(t1) + timeToMins(t0));
    } else {
        return timeFromMins(timeToMins(t1) - timeToMins(t0));
    }

}
