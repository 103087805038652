import React, {useState} from 'react';
import s from './styles.module.css'
import InputMask from "react-input-mask";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';
import {addTimes} from "../../../../utils/plus_time";

const TimePiker = ({setTime, time, isEdit}) => {
    const { t } = useTranslation()

    return (
        <div className={s.time_picker}>
            <div className={s.time_box}>
                <p>
                    {t("startTime")}
                </p>
                <InputMask disabled maskChar={null} mask={'99:99'} placeholder={t("enterTime")}
                           value={time.start_time}
                           onChange={(e) => {

                               setTime({...time, start_time: e.target.value})
                           }}
                />
            </div>
            <div className={classNames(s.time_box, s.time_box_middle)}>
                <p>
                    {t("endTime")}
                </p>
                <InputMask disabled={isEdit} maskChar={null} mask={'99:99'} placeholder={t("enterTime")}
                           value={time.end_time}
                           onChange={(e) => {
                               const value = e.target.value.split(':')
                               if (value[0] > 59) {
                                   setTime({
                                       ...time,
                                       end_time: `59:${value[1]}`,
                                       duration: addTimes(time.start_time, `59:${value[1]}`)
                                   })

                                   return
                               }
                               if (value[1] > 59) {
                                   setTime({
                                       ...time,
                                       end_time: `${value[0]}:59`,
                                       duration: addTimes(time.start_time, `${value[0]}:59`)
                                   })
                                   return
                               }

                               setTime({
                                   ...time,
                                   end_time: e.target.value,
                                   duration: addTimes(time.start_time, e.target.value)
                               })
                           }}
                />
            </div>
            <div className={s.time_box}>
                <p>
                    {t("duration")}
                </p>
                <InputMask disabled={isEdit} maskChar={null} mask={'99:99'} placeholder={t("enterTime")}
                           value={time.duration}
                           onChange={(e) => {
                               const value = e.target.value.split(':')
                               if (value[0] > 59) {
                                   setTime({
                                       ...time,
                                       duration: `59:${value[1]}`,
                                       end_time: addTimes(time.start_time, `59:${value[1]}`, 'p')
                                   })

                                   return
                               }
                               if (value[1] > 59) {
                                   setTime({
                                       ...time,
                                       duration: `${value[0]}:59`,
                                       end_time: addTimes(time.start_time, `${value[0]}:59`, 'p')
                                   })
                                   return
                               }

                               setTime({
                                   ...time,
                                   end_time: addTimes(time.start_time, e.target.value, 'p'),
                                   duration: e.target.value
                               })
                           }}
                />
            </div>
        </div>
    );
};

export default TimePiker;
