import api from "../../api/api";

class AuthServices {
    async getToken() {
        return await api().get('api/auth/check_token')
    }

    async checkToken(token) {
        return await api().get(`api/auth/check_token/${token}`)
    }

    async checkHash(hash) {
        return await api().get(`api/auth/check_application/${hash}`)
    }
}

export default new AuthServices()
