import React from 'react';
import s from './styles.module.css'
import classNames from 'classnames'
import {Tooltip} from "antd";
import {ReactComponent as Delete} from "../../assets/deleteRow.svg";

const Row = ({
                 children,
                 style,
                 handleDelete,
                 width,
                 position = 'left',
                 isNeedDelete = false,
                 isHeader = false,
                 needMaxWidth = false
             }) => {
    return (
        <div className={s.row} style={{
            minWidth: width,
            maxWidth: width,
            width,
            ...style
        }}>
            {/*<Tooltip title={needMaxWidth ? children : ''}>*/}
            <span className={classNames(isHeader ? s.header : s.not_header)} style={{
                textAlign: position,
                maxWidth: needMaxWidth ? '85%' : '100%'
            }}>
                {children}
            </span>
            {/*</Tooltip>*/}

            {isNeedDelete && <div className={s.delete} onClick={handleDelete}>
                <Delete/>
            </div>}
        </div>
    );
};

export default Row;
