import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {Select, Tag} from "antd";
import classNames from "classnames";
import {useStore} from "../../../../hook/useStore";
import { useTranslation } from 'react-i18next'
import {getCookies} from "../../../../utils/getCookies";
import {observer} from "mobx-react-lite";

const Hybid = observer(({setItemData, itemData, navigate, onChange, data, isEdit}) => {
    const {table, auth} = useStore()
    const { t } = useTranslation()
    const {name: token_name, value: token_value} = getCookies('token')
    const {name: hash_name, value: hash_value} = getCookies('hash')

    const [selectData, setSelectData] = useState({})

    useEffect(() => {
        const getTechDir = () => {
            table.getBonusElementHybrid(auth.type === 'token' ? token_value : hash_value,)
        }
        getTechDir()
    }, [localStorage.getItem('LOCALE')])

    useEffect(() => {
        setSelectData({
            bonus: table?.hybrid_bonus_element_dir?.bonus?.map((el) => ({
                title: el.group_hybrid_bonus_name,
                data: el.hybrids_bonus,
                id: el.group_hybrid_bonus_id,
                ...el
            })),
            elements: table?.hybrid_bonus_element_dir?.elements?.map((el) => ({
                title: el.group_hybrid_name,
                data: el.hybrids,
                id: el.group_hybrid_id,
                ...el
            }))
        })


    }, [table.loading_dir, table?.hybrid_bonus_element_dir])

    useEffect(() => {
        if (isEdit) {
            const hard_items = table.edit_element.directory_params
            const bonus_items = table.edit_element.directory_params_bonus

            let some_hard_data = []
            let some_bonus_data = []


            for (let k = 0; k < hard_items.length; k++) {
                for (let y = 0; y < table?.hybrid_bonus_element_dir?.elements?.length; y++) {
                    const findElement = table?.hybrid_bonus_element_dir.elements[y]?.hybrids.find((f) => +f.hybrid_id === +hard_items[k])

                    some_hard_data.push(findElement ? findElement : '')
                }
            }

            for (let k = 0; k < bonus_items.length; k++) {
                for (let y = 0; y < table?.hybrid_bonus_element_dir?.bonus?.length; y++) {
                    const findElement = table?.hybrid_bonus_element_dir.bonus[y]?.hybrids_bonus.find((f) => +f.hybrid_bonus_id === +bonus_items[k])

                    some_bonus_data.push(findElement ? findElement : '')
                }
            }

            setItemData({
                ...itemData, [navigate]: {
                    ...itemData[navigate],
                    'hard': some_hard_data.filter((f) => f !== '').map((el) => {
                        return ({
                            value: el.hybrid_short,
                            id: el.hybrid_id,
                            ks: el.hybrid_value
                        })
                    }),
                    "bonus": some_bonus_data?.filter((f) => f !== '')?.map((el) => {
                        return ({
                            value: el.hybrid_bonus_short,
                            id: el.hybrid_bonus_id,
                            ks: el.hybrid_bonus_value
                        })
                    })

                }
            })
        }
    }, [table?.hybrid_bonus_element_dir])

    return (
        <div className={s.hybid}>
            <div className={s.family}>
                <h3 className={s.title}>{t("movements")}</h3>
                <div className={s.select_box}>
                    {selectData?.elements?.map((select, i) => <Select
                        key={i}
                        popupClassName={s.test}
                        className={s.select}
                        value={select.title}
                        placeholder={select.title}
                        defaultValue={select.title}
                        style={{maxWidth: '236px', height: '48px'}}
                        onChange={(a, b) => {
                            // if (data['hard'].find((f) => f.id === b.hybrid_id)) return
                            onChange('hard', [...data['hard'], {
                                value: b.short,
                                id: b.hybrid_id,
                                ks: b.hybrid_value
                            }])
                        }}
                        options={select?.data?.map((el) => ({
                            value: `${el.hybrid_short} - ${el.hybrid_value}`,
                            id: el.hybrid_id,
                            short: el.hybrid_short,
                            // disabled: data['hard']?.find((f) => f?.id === el?.hybrid_id),
                            ...el
                        }))}
                    />)}
                </div>
            </div>

            <div className={s.bonus}>
                <h3 className={s.title}>{t("bonuses")}</h3>
                <div className={classNames(s.select_box, s.select_box_2)}>
                    {selectData?.bonus?.map((select, i) => <Select
                        key={i}
                        popupClassName={s.test}
                        className={s.select}
                        value={select.title}
                        placeholder={select.title}
                        defaultValue={select.title}
                        style={{maxWidth: '300px', height: '48px'}}
                        onChange={(a, b) => {
                            // if (data['bonus'].find((f) => f.id === b.hybrid_bonus_id)) return
                            onChange('bonus', [...data['bonus'], {
                                value: b.short,
                                id: b.hybrid_bonus_id,
                                ks: b.hybrid_bonus_value
                            }])
                        }}
                        options={select?.data?.map((el) => ({
                            value: `${el.hybrid_bonus_short} - ${el.hybrid_bonus_value}`,
                            id: el.hybrid_bonus_id,
                            short: el.hybrid_bonus_short,
                            // disabled: data['bonus']?.find((f) => f?.id === el?.hybrid_bonus_id),
                            ...el
                        }))}
                    />)}

                </div>
            </div>
        </div>
    );
});

export default Hybid;
