import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {Select} from "antd";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../../hook/useStore";
import {getCookies} from "../../../../utils/getCookies";


const Acro = observer(({isEdit, data, onChange, setNavigate, navigate}) => {
    const {table, auth} = useStore()

    const {name: token_name, value: token_value} = getCookies('token')
    const {name: hash_name, value: hash_value} = getCookies('hash')

    // const [navigate, setNavigate] = useState({})
    const [acroType, setAcroType] = useState([])
    const [acroSelect, setAcroSelect] = useState([])

    useEffect(() => {
        const getAcroTypeDir = () => {
            table.getAcroTypeDir()
        }

        getAcroTypeDir()
    }, [localStorage.getItem('LOCALE')])

    useEffect(() => {
        setAcroType(table.acro_type)
        if (navigate?.acro_type_id) return
        setNavigate(table.acro_type[0])
        if (isEdit) {
            setNavigate(table.acro_type.find(f => f.acro_type_id === table.edit_element.directory_acro_type_id) || table.acro_type[0])
        }
    }, [table.loading_dir])

    useEffect(() => {
        const getAcroDir = async () => {
            if (navigate?.acro_type_id) {
                await table.getAcroDir(navigate.acro_type_id, auth.type === 'token' ? token_value : hash_value)
                setAcroSelect(table.acro_dir.map((el) => ({
                    title: el.group_acro_name,
                    data: el.acro,
                    id: el.group_acro_id,
                    ...el
                })))
            }

            if (isEdit && table.edit_element.directory_acro_type_id === navigate?.acro_type_id) return
            onChange('hard', [])


        }

        getAcroDir()

    }, [navigate?.acro_type_id, localStorage.getItem('LOCALE')])

    useEffect(() => {
        if (isEdit) {
            const data_edit = table.edit_element.directory_params

            let some_hard_data = []

            for (let i = 0; i < table?.acro_dir?.length; i++) {
                const find_element = table?.acro_dir[i]?.acro?.filter((f) => data_edit.some((s) => +s === +f.acro_id))
                if (find_element?.length !== 0 && find_element) {
                    some_hard_data = [...some_hard_data, ...find_element]
                }

            }

            // const find_element = table?.acro_dir.find((f) => +f?.tech_id === +data_edit[0])
            onChange('hard', (data && data['hard']) ? [...some_hard_data.map((el) => ({
                value: el.acro_name,
                id: el.acro_id,
                ks: el.acro_value
            }))] : [])


        }
        return () => {
            onChange('hard', [])
        }
    }, [table?.acro_dir])

    return (
        <div className={s.acro}>
            <div className={s.acro_navigate}>
                <div className={s.line} style={{
                    left: `${navigate === 1 ? 0 : (((acroType.findIndex(f => f?.acro_type_id === navigate?.acro_type_id))) * (100 / acroType.length))}%`,
                }}/>
                {acroType?.map((type) => <div key={type.acro_type_id} className={s.acro_item}
                                              onClick={() => setNavigate(type)}>{type.acro_type_name}</div>)}
            </div>

            <div className={s.content} style={{
                gridTemplateColumns: `repeat(${acroSelect?.length}, 1fr)`
            }}>
                {acroSelect?.map((select, i) => <Select
                    key={i}
                    className={s.select}
                    value={select.title}
                    placeholder={select.title}
                    defaultValue={select.title}
                    dropdownStyle={{ minWidth: '200px' }}
                    style={{maxWidth: '200px', height: '38px'}}
                    onChange={(a, b) => {
                        if (data['hard'].find((f) => f.id === b.id)) return
                        const findSame = data['hard'].find((f) => f.id === b.id)

                        onChange('hard', findSame ? data['hard'].map((el) => el.id === b.id ? ({
                            ...el,
                            value: b.short
                        }) : el) : [...data['hard'], {value: b.short, id: b.acro_id, ks: b.acro_value}])

                    }}
                    options={select?.data?.map((el) => ({
                        value: `${el.acro_name} - ${el.acro_value}`,
                        short: el.acro_name,
                        id: el.acro_id,
                        disabled: data['hard']?.find((f) => f?.id === el?.acro_id),
                        ...el
                    }))}
                />)}
            </div>
        </div>
    );
});

export default Acro;
