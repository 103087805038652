import React, {useEffect, useState} from 'react';
import s from './styles.module.css'
import {Select} from "antd";
import { useTranslation } from 'react-i18next';
import {useStore} from "../../../../hook/useStore";
import {observer} from "mobx-react-lite";
import {getCookies} from "../../../../utils/getCookies";

const Tre = observer(({isEdit, data, onChange}) => {
    const {table, auth} = useStore()
    const { t } = useTranslation()
    const {name: token_name, value: token_value} = getCookies('token')
    const {name: hash_name, value: hash_value} = getCookies('hash')

    const [selectData, setSelectData] = useState([])

    useEffect(() => {
        const getTechDir = () => {
            table.getTechDir(auth.type === 'token' ? token_value : hash_value,)
        }
        getTechDir()
    }, [])

    useEffect(() => {
        setSelectData(table?.tech_dir?.map((el) => ({
            value: el.tech_id,
            label: `${el.tech_short} - ${el.tech_value}`,
            short: el.tech_short,
            ...el
        })))


    }, [table.loading_dir, table?.tech_dir])

    useEffect(() => {
        if (isEdit) {
            const data_edit = table.edit_element.directory_params
            if (!data_edit || data_edit.length === 0) return
            const find_element = table?.tech_dir.find((f) => +f?.tech_id === +data_edit[0])
            if (!find_element) return

            onChange('hard', [{
                value: find_element?.tech_short,
                id: find_element?.tech_id,
                ks: find_element?.tech_value
            }])
        }
    }, [table?.tech_dir])

    return (
        <div className={s.tre}>
            <Select
                className={t("technicalRequiredElement")}
                value={t("technicalRequiredElement")}
                placeholder={t("technicalRequiredElement")}
                defaultValue={t("technicalRequiredElement")}
                style={{maxWidth: '670px', height: '48px'}}
                onChange={(a, b) => {
                    if (data['hard'].find((f) => f.tech_id === b.tech_id)) return
                    onChange('hard', [{value: b.short, id: b.tech_id, ks: b.tech_value}])
                }}
                options={selectData.map((el) => ({...el, disabled: data['hard']?.find((f) => f?.id === el?.tech_id)}))}
            />
        </div>
    );
});

export default Tre;
