import './App.css';
import Router from "./routers/router";
import Container from "./component/container";
import {observer} from "mobx-react-lite";
import {useStore} from "./hook/useStore";
import React, {useEffect} from "react";
import {getCookies} from "./utils/getCookies";
import {useLocation, useNavigate} from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {Box, Modal, Skeleton, Typography} from "@mui/material";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setLanguage } from './localization';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
};

const App = observer(() => {
    const {auth} = useStore()
    const { t } = useTranslation()

    const [searchParams] = useSearchParams();
    const lang = searchParams.get('lang')
    const navigate = useNavigate()
    const location = useLocation()
    const isHaveHash = searchParams.get('app')

    useEffect(() => {
        if(lang) {
            setLanguage(lang)
        } else (
            setLanguage('ru')
        )
        if (isHaveHash) {
            const setHash = async () => {
                await auth.setHash(isHaveHash)
            }
            setHash()

        } else {
            const getToken = () => {
                auth.getToken()
            }
            getToken()
        }


    }, [])

    useEffect(() => {
        if (!isHaveHash) return
        if (!isHaveHash && !lang) {
            navigate('/')
            window.history.pushState("", document.title, window.location.pathname
                + window.location.search);
        }
    }, [])

    if (auth.loading) {
        return null
    }

    if (auth.hash_error.isError) {
        return <div>
            <Skeleton variant="rounded" width={'100%'} height={200} sx={{marginTop: '24px'}}/>
            <Skeleton variant="rounded" width={'100%'} height={300} sx={{marginTop: '24px'}}/>

            <Modal
                open={auth.hash_error.isError}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t("error")}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        {auth.hash_error.textError}
                    </Typography>
                </Box>
            </Modal>
        </div>
    }
    return (
        <div className="App">
            <ToastContainer/>

            <Container>
                <Router/>
            </Container>
        </div>
    );
})

export default App;
