import {makeAutoObservable} from "mobx";
import DistiplinesServices from "./srvices/distiplines.services";
import {toast} from "react-toastify";

export class DistiplinesStore {
    loading = false
    card_info = {}
    distiplines = []

    constructor(props) {
        makeAutoObservable(this)
    }


    setLoading(loading) {
        this.loading = loading
    }

    setCardInfo(card_info) {
        this.card_info = card_info
    }

    setDistiplines(distiplines) {
        this.distiplines = distiplines
    }

    async getDistiplinesCardInfo(tokenOrHash) {
        this.setLoading(true)
        try {
            const res = await DistiplinesServices.getInfoCard(tokenOrHash)
            this.setCardInfo(res.data)

        } catch (e) {
            console.log(e)
        } finally {
            this.setLoading(false)
        }
    }

    async downloadFile(tokenOrHash) {
        try {
            return await DistiplinesServices.downloadFile(tokenOrHash)
        } catch (e) {
            console.log(e)
        }
    }

    async uploadFile(formData, tokenOrHash) {
        const locale = localStorage.getItem('LOCALE')
        try {
            const res = await DistiplinesServices.uploadFile(formData, tokenOrHash)

            if (res?.data?.status === 'ok') {
                toast.success(locale === 'ru' ? 'Файл успешно загружен!' : "The file has been uploaded successfully!", {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
            }
        } catch (e) {
            console.log(e)

        }
    }

    async getDistiplines() {
        this.setLoading(true)
        try {
            const res = await DistiplinesServices.getDistiplines()
            this.setDistiplines(res.data.disciplines)

        } catch (e) {
            console.log(e)
        } finally {
            this.setLoading(false)
        }
    }

    async setDistiplinesThemeMusic(tokenOrHash, payload) {
        try {
            const res = await DistiplinesServices.setDistiplinesThemeMusic(tokenOrHash, payload)

        } catch (e) {
            console.log(e)

        }
    }

    async saveCard(hash) {
        const locale = localStorage.getItem('LOCALE')
        try {
            const res = await DistiplinesServices.saveCard(hash)

            if (res?.data?.status === 'ok') {
                toast.success(locale === 'ru' ? 'Сохранено!' : "Saved!", {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                })
            }
        } catch (e) {
            console.log(e)

        }
    }

    async clearCard(token, refreshTable) {
        const locale = localStorage.getItem('LOCALE')
        try {
            const res = await DistiplinesServices.clearCard(token)
            refreshTable()
            toast.success(locale === 'ru' ? 'Успешно!' : 'Successfully!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        } catch (e) {
            console.log(e)
            toast.error(locale === 'ru' ? 'Ошибка!' : 'Error!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            })
        }
    }

}
