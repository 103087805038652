import React, {useState} from 'react';
import s from './styles.module.css'

const Navigate = ({navigate = 1, setNavigate, types = []}) => {

    return (
        <div className={s.navigate} style={{gridTemplateColumns: `repeat(${types?.length}, 1fr)`}}>
            <div className={s.line} style={{
                width: `calc(100% / ${types?.length})`,
                left: `${navigate === 1 ? 0 : (((types.findIndex(f => f?.directory_type_id === navigate))) * (100 / types?.length))}%`,
            }}/>
            {types.map((el) => <div key={el.directory_type_id} className={s.navigate_item}
                                    onClick={() => setNavigate(el.directory_type_id)}>{el.directory_type_name}</div>)}
        </div>
    );
};

export default Navigate;
