import React from 'react';
import s from './styles.module.css'
import { useTranslation } from 'react-i18next'
import Row from "../../../../component/row";

const AddedItem = ({time, part,navigate, el, hard_data, ks, bonus_data, delete_hard, delete_bonus,text}) => {
    const { t } = useTranslation()

    return (
        <div className={s.item}>
            <div className={s.header_item}>
                <Row style={{padding: '24px 16px'}} width={160} isHeader>{t("time")}</Row>
                <Row style={{padding: '24px 16px'}} width={130} isHeader>{t("part")}</Row>
                <Row style={{padding: '24px 16px'}} width={80} isHeader>{t("el")}</Row>
                <Row style={{padding: '24px 16px'}} width={344} isHeader>{t("declaredDifficulty")}</Row>
                <Row style={{padding: '24px 16px'}} width={294} isHeader>{t("bonus")}</Row>
                <Row style={{padding: '24px 16px'}} width={90} isHeader>{t("dd")}</Row>
            </div>

            <div className={s.content_item}>
                <Row width={160}>{time.start_time} - {time.end_time}</Row>
                <Row width={130}>{part}</Row>
                <Row width={80}>{el}</Row>
                <Row width={344} handleDelete={delete_hard} needMaxWidth
                     isNeedDelete={Array.isArray(hard_data) ? hard_data?.length !== 0 : false}
                >
                    {navigate !== 4 ? (Array.isArray(hard_data) ? hard_data?.map((el) => (el.value ? el.value : el) + ' ') : null) : text}
                </Row>
                <Row width={294} handleDelete={delete_bonus} needMaxWidth
                     isNeedDelete={Array.isArray(bonus_data) ? bonus_data?.length !== 0 : false}
                >
                    {Array.isArray(bonus_data) ? bonus_data?.map((el) => (el.value ? el.value : el) + ' ') : null}
                </Row>
                <Row width={90}>{ks}</Row>
            </div>
        </div>
    );
};

export default AddedItem;
