import {makeAutoObservable} from "mobx";
import AuthServices from "./srvices/auth.services";
import {getCookies} from "../utils/getCookies";

export class AuthStore {
    loading = true
    type = null
    value = null
    hash_error = {
        isError: false,
        textError: ''
    }

    constructor() {
        makeAutoObservable(this)
    }

    setLoading(loading) {
        this.loading = loading
    }

    setHashError(hash_error, hash_text) {
        this.hash_error = {
            isError: hash_error,
            textError: hash_text,
        }
    }

    setTypeAndValue(type, value) {
        this.type = type
        this.value = value
    }

    async getToken() {
        this.setLoading(true)
        const {name: token_name, value: token_value} = getCookies('token')

        try {
            if (token_name === 'token') {
                const res = await AuthServices.checkToken(`${token_value}`)

                this.setTypeAndValue('token', res.data.token)
                const daysToExpire = new Date(2147483647 * 1000).toUTCString();
                document.cookie = `token=${res.data.token}; expires=${daysToExpire}`
            } else {
                const res = await AuthServices.getToken()

                this.setTypeAndValue('token', res.data.token)
                const daysToExpire = new Date(2147483647 * 1000).toUTCString();
                document.cookie = `token=${res.data.token}; expires=${daysToExpire}`
            }

        } catch (e) {
            console.log(e)
        } finally {
            this.setLoading(false)
        }
    }

    async setHash(value) {
        this.setLoading(true)
        const {name: hash_name, value: hash_value} = getCookies('hash')
        try {
            if (hash_name === 'hash') {
                const res = await AuthServices.checkHash(value)
                
                if (res.data.status === 'error' || !res.data.check || !res.data.access) {
                    this.setHashError(true, res?.data?.error || 'Что-то не так')
                } else {
                    this.setTypeAndValue('hash', value)
                    document.cookie = `hash=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`
                }

            } else {
                this.setTypeAndValue('hash', value)
                document.cookie = `hash=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`
            }

        } catch (e) {
            console.log(e)
        } finally {
            this.setLoading(false)
        }
    }
}
