import {AuthStore} from "./auth.store";
import {DistiplinesStore} from "./distiplines.store";
import {TableStore} from "./table.store";

class Store {
    constructor() {
        this.auth = new AuthStore()
        this.distiplines = new DistiplinesStore()
        this.table = new TableStore()
    }
}

export const store = new Store()
